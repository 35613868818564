import { Chip } from '@material-ui/core';
import React from 'react';

export default [
  {
    field: 'isCustom',
    title: 'Custom Data',
    togglingEnabled: true,
    width: 100,
    hidden: false,
    render: (rowdata) => <Chip size="small" label={rowdata.isCustom ? 'Custom' : 'Original'} />,
  },
  {
    field: 'noradId',
    sortingEnabled: true,
    title: 'Cat. ID',
    togglingEnabled: false,
    width: 100,
    hidden: false,
  },
  {
    field: 'intldes',
    sortingEnabled: true,
    title: 'Int. Designator',
    togglingEnabled: false,
    width: 150,
    hidden: false,
  },
  {
    field: 'name',
    title: 'Name',
    sortingEnabled: true,
    togglingEnabled: false,
    width: 'auto',
    hidden: false,
  },
  {
    field: 'type',
    title: 'Type',
    sortingEnabled: true,
    togglingEnabled: true,
    width: 100,
    hidden: true,
  },
  {
    field: 'country',
    title: 'Country',
    sortingEnabled: true,
    togglingEnabled: true,
    width: 80,
    hidden: false,
  },
  {
    field: 'launch',
    sortingEnabled: true,
    title: 'Launch Date',
    togglingEnabled: true,
    width: 160,
    hidden: false,
  },
  {
    field: 'site',
    title: 'Launch Site',
    sortingEnabled: true,
    togglingEnabled: true,
    width: 100,
    hidden: false,
  },
  // { name: 'DECAY', title: 'Decay Date', togglingEnabled: true, width: 100, hidden: true },
  {
    field: 'period',
    title: 'Period [min.]',
    sortingEnabled: true,
    togglingEnabled: true,
    width: 100,
    hidden: true,
  },
  {
    field: 'inclination',
    title: 'Inclination [deg.]',
    sortingEnabled: true,
    togglingEnabled: true,
    width: 140,
    hidden: false,
  },
  {
    field: 'apogee',
    title: 'Apogee [km]',
    sortingEnabled: true,
    togglingEnabled: true,
    width: 100,
    hidden: false,
  },
  {
    field: 'perigee',
    sortingEnabled: true,
    title: 'Perigee [km]',
    togglingEnabled: true,
    width: 100,
    hidden: false,
  },
  {
    field: 'rcs',
    title: 'RCS',
    sortingEnabled: true,
    togglingEnabled: true,
    width: 100,
    hidden: true,
  },
];
