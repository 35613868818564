import {
  CustomPaging,
  IntegratedSelection,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import React from 'react';
import ChipTypeProvider from 'services/orbital-elements/object-catalog/ObjectCatalogTable/datatypes/ChipTypeProvider';
import DateTimeTypeProvider from 'services/orbital-elements/object-catalog/ObjectCatalogTable/datatypes/DateTimeTypeProvider';

const ReactDxTable = ({
  BulkSelectAll,
  columns,
  ColumnChooserButton,
  columnWidths,
  customSelectCheckbox,
  customSelectCurrentPageCheckbox,
  currentPage,
  getColumns,
  setColumnWidths,
  getRowId,
  getSorting,
  getVisibility,
  hiddenColumnNames,
  loading,
  onCurrentPageChange,
  onPageSizeChange,
  onSortingChange,
  pageSize,
  pageSizes,
  rows,
  totalCount,
  selection,
  sorting,
  ResetColumnWidthsButton,
  setHiddenColumnNames,
  onSelectionChange,
  RefreshTableButton,
}) => {
  const LoadingState = () => (
    <td colSpan={columns.length} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
      <big>{loading ? <CircularProgress size={50} /> : <span>No data</span>}</big>
    </td>
  );
  return (
    <>
      <Grid rows={rows} columns={getColumns(columns)} getRowId={getRowId}>
        <ChipTypeProvider for={['isCustom']} />
        <DateTimeTypeProvider for={['launch']} />
        <SelectionState selection={selection} onSelectionChange={onSelectionChange} />
        <IntegratedSelection />
        <Table noDataCellComponent={() => <LoadingState />} />
        <TableColumnVisibility
          defaultHiddenColumnNames={hiddenColumnNames || []}
          onHiddenColumnNamesChange={setHiddenColumnNames}
          columnExtensions={getVisibility(columns)}
        />
        <Toolbar />
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={onCurrentPageChange}
          pageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
        />
        <CustomPaging totalCount={totalCount} />
        <SortingState
          columnExtensions={getSorting(columns)}
          sorting={sorting}
          onSortingChange={onSortingChange}
          toggleButtonComponent={<Button>asd</Button>}
        />
        <ColumnChooser toggleButtonComponent={ColumnChooserButton} />
        <TableColumnResizing
          resizingMode="nextColumn"
          columnWidths={columnWidths}
          onColumnWidthsChange={setColumnWidths}
        />
        <TableSelection
          showSelectAll
          cellComponent={customSelectCheckbox}
          headerCellComponent={customSelectCurrentPageCheckbox}
        />
        <TableHeaderRow showSortingControls />
        <BulkSelectAll />
        <ResetColumnWidthsButton />
        <RefreshTableButton />
        <PagingPanel pageSizes={pageSizes} />
      </Grid>
    </>
  );
};

ReactDxTable.propTypes = {
  BulkSelectAll: PropTypes.func.isRequired,
  ResetColumnWidthsButton: PropTypes.func.isRequired,
  RefreshTableButton: PropTypes.func.isRequired,
  hiddenColumnNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  setHiddenColumnNames: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  totalCount: PropTypes.number.isRequired,
  selection: PropTypes.arrayOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
  sorting: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getRowId: PropTypes.func.isRequired,
  getSorting: PropTypes.func.isRequired,
  getColumns: PropTypes.func.isRequired,
  getVisibility: PropTypes.func.isRequired,
  ColumnChooserButton: PropTypes.func.isRequired,
  columnWidths: PropTypes.arrayOf(PropTypes.any).isRequired,
  setColumnWidths: PropTypes.func.isRequired,
  customSelectCheckbox: PropTypes.func.isRequired,
  customSelectCurrentPageCheckbox: PropTypes.func.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  onCurrentPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  onSortingChange: PropTypes.func.isRequired,
};

export default ReactDxTable;
