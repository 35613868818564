import { Template, TemplatePlaceholder } from '@devexpress/dx-react-core';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, FormControlLabel, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
  getColumns,
  getDefaultHidden,
  getDefaultWidths,
  getSorting,
  getVisibility,
} from '#utils/ReactGridColumnHelper';

import columns from './columns';
import Table from './ReactDxTable';
import SelectAllCheckbox from './SelectAllCheckbox';
import SelectCheckbox from './SelectCheckbox';
import ColumnChooserButton from './toolbar/ColumnChooserButton';
import RefreshRowsButton from './toolbar/RefreshRowsButton';
import ResetColumnWidthButton from './toolbar/ResetColumnWidthButton';

const getRowId = (row) => row._id;

const ObjectCatalogTable = ({
  bulkDeleteSatellites,
  bulkSyncTle,
  currentPage,
  dispatch,
  rows,
  totalCount,
  selection,
  update,
  loading,
  pageSize,
  pageSizes,
  sorting,
  state,
}) => {
  const [columnWidths, setColumnWidths] = useState(getDefaultWidths(columns));

  const resetWidths = () => {
    setColumnWidths(getDefaultWidths(columns));
  };

  const BulkSelectAll = () => (
    <Template name="toolbarContent">
      <TemplatePlaceholder />
      <FormControlLabel
        control={
          <Checkbox
            checked={state.isAllSelected}
            onChange={(event) => {
              const { checked } = event.target;

              if (!checked) {
                dispatch({ type: 'setSelection', data: [] });
              }
              dispatch({ type: 'setIsAllSelected', data: checked });
            }}
            value={state.isAllSelected}
            color="secondary"
          />
        }
        label={`Select all (${totalCount}) items.`}
      />
      {(state.isAllSelected || selection.length > 0) && (
        <>
          <FormControlLabel
            control={
              <IconButton color="secondary" onClick={() => bulkDeleteSatellites(selection, state.isAllSelected)}>
                <FontAwesomeIcon icon={faTrash} />
              </IconButton>
            }
          />
          <Button
            color="primary"
            variant="outlined"
            style={{ marginRight: 15 }}
            onClick={() => bulkSyncTle(selection, state.isAllSelected, true)}
          >
            Set Tracking on
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => bulkSyncTle(selection, state.isAllSelected, false)}
          >
            Set Tracking off
          </Button>
        </>
      )}
    </Template>
  );

  const ResetColumnWidthsButton = () => (
    <Template name="toolbarContent">
      <TemplatePlaceholder />
      <ResetColumnWidthButton onClick={resetWidths} />
    </Template>
  );

  const RefreshTableButton = () => (
    <Template name="toolbarContent">
      <TemplatePlaceholder />
      <RefreshRowsButton onClick={update} />
    </Template>
  );

  function onSelectionChange(newSelection) {
    dispatch({ type: 'setSelection', data: newSelection });
  }

  function onCurrentPageChange(page) {
    dispatch({ type: 'setCurrentPage', data: page });
  }

  function onPageSizeChange(size) {
    dispatch({ type: 'setPageSize', data: size });
  }

  function onSortingChange(sortArray) {
    dispatch({ type: 'setSorting', data: sortArray });
    update();
  }

  const customRowCheckbox = (props) => <SelectCheckbox {...props} state={state} dispatch={dispatch} />;

  const customPageSelectCheckbox = (props) => <SelectAllCheckbox {...props} state={state} dispatch={dispatch} />;

  return (
    <Table
      ResetColumnWidthsButton={ResetColumnWidthsButton}
      RefreshTableButton={RefreshTableButton}
      BulkSelectAll={BulkSelectAll}
      hiddenColumnNames={state.hidden}
      setHiddenColumnNames={(arr) => dispatch({ type: 'setHiddenColumnNames', data: arr })}
      bulkSyncTle={bulkSyncTle}
      currentPage={currentPage}
      rows={rows}
      totalCount={totalCount}
      selection={selection}
      loading={loading}
      pageSize={pageSize}
      pageSizes={pageSizes}
      sorting={sorting}
      columns={columns}
      getColumns={getColumns}
      getRowId={getRowId}
      onSelectionChange={onSelectionChange}
      onCurrentPageChange={onCurrentPageChange}
      onPageSizeChange={onPageSizeChange}
      onSortingChange={onSortingChange}
      getSorting={getSorting}
      getDefaultHidden={getDefaultHidden}
      getVisibility={getVisibility}
      ColumnChooserButton={ColumnChooserButton}
      columnWidths={columnWidths}
      setColumnWidths={setColumnWidths}
      customSelectCheckbox={customRowCheckbox}
      customSelectCurrentPageCheckbox={customPageSelectCheckbox}
      update={update}
    />
  );
};

ObjectCatalogTable.propTypes = {
  bulkDeleteSatellites: PropTypes.func.isRequired,
  bulkSyncTle: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  totalCount: PropTypes.number.isRequired,
  selection: PropTypes.arrayOf(PropTypes.string).isRequired,
  update: PropTypes.func.isRequired,
  sorting: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
  state: PropTypes.shape({
    hidden: PropTypes.arrayOf(PropTypes.string).isRequired,
    isAllSelected: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ObjectCatalogTable;
