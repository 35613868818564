/* eslint-disable react/forbid-prop-types */
import LuxonUtils from '@date-io/luxon';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import RangeInputStyles from './RangeInputStyles';

const useStyles = makeStyles(RangeInputStyles);

const RangeInput = ({ startTime, endTime, onStartChange, onEndChange }) => {
  const [startDate, setStartDate] = useState(DateTime.fromMillis(startTime));
  const [endDate, setEndDate] = useState(DateTime.fromMillis(endTime));
  const classes = useStyles();

  useEffect(() => {
    onStartChange(startDate.toMillis());
    onEndChange(endDate.toMillis());
  }, [startDate, endDate]);

  return (
    <Paper className={classes.container}>
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        {/* https://material-ui-pickers.dev/demo/datetime-picker */}
        <KeyboardDateTimePicker
          clearable
          className={classes.picker}
          disableFuture
          showTodayButton
          ampm={false}
          maxDate={endDate.toLocal()}
          label="From"
          value={startDate.toLocal()}
          onChange={(date) => {
            setStartDate(date);
          }}
          format="yyyy/MM/dd HH:mm"
        />
        <KeyboardDateTimePicker
          className={classes.picker}
          disableFuture
          showTodayButton
          minDate={startDate.toLocal()}
          ampm={false}
          label="To"
          value={endDate.toLocal()}
          onChange={(date) => {
            setEndDate(date);
          }}
          format="yyyy/MM/dd HH:mm"
        />
      </MuiPickersUtilsProvider>
    </Paper>
  );
};

RangeInput.propTypes = {
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  onStartChange: PropTypes.func.isRequired,
  onEndChange: PropTypes.func.isRequired,
};

export default RangeInput;
