import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import isEqual from 'react-fast-compare';

import styles from '#utils/styles';

const useStyles = makeStyles(styles);

const PullButton = ({ onPull, className, size, title }) => {
  const classes = useStyles();
  return (
    <Tooltip title={title}>
      <IconButton color="primary" className={`${className}`} onClick={onPull} size={size}>
        <div className={classes.fontAwesomeIconHolder}>
          <FontAwesomeIcon icon={['fas', 'cloud-download-alt']} size="xs" />
        </div>
      </IconButton>
    </Tooltip>
  );
};

PullButton.defaultProps = {
  title: 'Delete file',
  size: 'small',
  className: '',
};

PullButton.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'small']),
  className: PropTypes.string,
  onPull: PropTypes.func.isRequired,
};

export default memo(PullButton, isEqual);
