import { Button, Card, CardContent, CardHeader, Dialog, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import RgContext from '#contexts/RgContext';

const AddNewSatelliteDialog = ({
  newSatDialogOpen,
  toggleAddNewSatelliteDialog,
  classes,
  rpcWithErrorHandler,
  enqueueSnackbar,
  update,
}) => {
  const [satName, setSatName] = useState('');
  const { currentResGroup } = useContext(RgContext);
  const baseRpcEndpoint = `rg/${currentResGroup}/@c3s/orbital-element-provider`;

  const addNewSatellite = () => {
    try {
      rpcWithErrorHandler(`${baseRpcEndpoint}/addCustomSatellite`, { satelliteName: satName });
      toggleAddNewSatelliteDialog();
      update();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return (
    <Dialog open={newSatDialogOpen} aria-labelledby="form-dialog-title">
      <Card className={classes.Card} style={{ minWidth: 400 }}>
        <CardHeader title="Add new Satellite" titleTypographyProps={{ variant: 'h6' }} className={classes.CardHeader} />
        <CardContent className={classes.CardContent}>
          <TextField
            autoFocus
            margin="dense"
            id="satName"
            label="Satellite Name"
            onChange={(e) => setSatName(e.target.value)}
            fullWidth
          />
        </CardContent>
        <CardContent className={classes.CardContent}>
          <Button variant="contained" color="secondary" onClick={toggleAddNewSatelliteDialog}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={addNewSatellite}>
            Add
          </Button>
        </CardContent>
      </Card>
    </Dialog>
  );
};

AddNewSatelliteDialog.propTypes = {
  update: PropTypes.func.isRequired,
  newSatDialogOpen: PropTypes.bool.isRequired,
  toggleAddNewSatelliteDialog: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    Card: PropTypes.string,
    CardContent: PropTypes.string,
    CardHeader: PropTypes.string,
  }).isRequired,
  rpcWithErrorHandler: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default AddNewSatelliteDialog;
