import { Button, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const SourcePicker = ({ selectedSourceOption = 'none', dispatch, disabled = false }) => {
  const [source, setSource] = React.useState(selectedSourceOption);

  useEffect(() => {
    dispatch({ selectedSourceOption: source });
  }, [source, dispatch]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} lg={12}>
        <Typography style={{ fontWeight: 'bolder' }}>Source picker</Typography>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography>Current source: {selectedSourceOption}</Typography>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setSource('latest')}
              disabled={disabled}
              style={{ width: '100%', height: '100%' }}
            >
              Latest
            </Button>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setSource('fixed')}
              disabled={disabled}
              style={{ width: '100%', height: '100%' }}
            >
              Select from options
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

SourcePicker.propTypes = {
  selectedSourceOption: PropTypes.oneOf(['latest', 'fixed', 'none']).isRequired,
  dispatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default SourcePicker;
