import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import StatePropShape from './DxGridPropTypes';

const SelectAllCheckbox = ({ disabled, allSelected, someSelected, onToggle, state: { isAllSelected }, dispatch }) => {
  return (
    <TableCell>
      <Checkbox
        disabled={disabled}
        checked={isAllSelected || allSelected}
        indeterminate={someSelected}
        onChange={(event) => {
          dispatch({ type: 'setIsAllSelected', data: false });
          onToggle(event.target.checked);
        }}
      />
    </TableCell>
  );
};

SelectAllCheckbox.propTypes = {
  disabled: PropTypes.bool.isRequired,
  allSelected: PropTypes.bool.isRequired,
  someSelected: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,

  state: PropTypes.shape(StatePropShape).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default memo(SelectAllCheckbox);
