import PropTypes from 'prop-types';
import React from 'react';
import CreatableSelect from 'react-select/creatable';

const ChippedTextField = ({ passedArray, setPassedArray, placeholder }) => {
  return (
    <CreatableSelect
      placeholder={placeholder}
      isMulti
      onChange={setPassedArray}
      value={passedArray}
      style={{ minWidth: 150 }}
    />
  );
};

ChippedTextField.defaultProps = {
  passedArray: [],
};

ChippedTextField.propTypes = {
  placeholder: PropTypes.string.isRequired,
  passedArray: PropTypes.arrayOf(PropTypes.any),
  setPassedArray: PropTypes.func.isRequired,
};

export default ChippedTextField;
