import { ReactSelectAsyncMaterial } from '@c3s/ui-components';
import { FormLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import useSatPicker from './useSatPicker';

const SatPicker = ({ dispatch }) => {
  const { getTrackedSatellites, setSelectedSat } = useSatPicker({ dispatch });

  return (
    <>
      <FormLabel component="legend">Select satellite</FormLabel>
      <ReactSelectAsyncMaterial label="" defaultOptions loadOptions={getTrackedSatellites} onChange={setSelectedSat} />
    </>
  );
};

SatPicker.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default SatPicker;
