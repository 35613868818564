import React from 'react';

import ObjectCatalogPanel from '#services/orbital-elements/object-catalog/ObjectCatalogPanel';
import TleCatalogPanel from '#services/orbital-elements/tle-catalog/TleCatalogPanel';

export default [
  {
    name: 'Object Catalog',
    hash: 'object-catalog',
    container: <ObjectCatalogPanel />,
  },
  {
    name: 'TLE Catalog',
    hash: 'tle-catalog',
    container: <TleCatalogPanel />,
  },
];
