import { ReactTable } from '@c3s/ui-components';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import useTableColumns from './useTableColumns';

const TleCatalogTable = ({ selectedSatMongoId, dispatch, rows = [], currentTle, disablePick }) => {
  const [selectedTleId, setSelectedTleId] = useState('');
  const { columns } = useTableColumns(selectedTleId, disablePick);

  useEffect(() => {
    dispatch({ selectedTleId });
  }, [dispatch, selectedTleId]);

  useEffect(() => setSelectedTleId(''), [selectedSatMongoId]);

  return (
    <>
      <Typography>
        Current TLE in use: {currentTle?.title ?? 'none'} {currentTle?.line1} {currentTle?.line2}{' '}
      </Typography>
      <ReactTable
        columns={columns}
        skipPageReset
        data={rows}
        onRowClick={(row) => setSelectedTleId(row.original._id)}
      />
    </>
  );
};

TleCatalogTable.defaultProps = {
  selectedSatMongoId: '',
  currentTle: null,
  disablePick: true,
};

TleCatalogTable.propTypes = {
  selectedSatMongoId: PropTypes.string,
  disablePick: PropTypes.bool,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dispatch: PropTypes.func.isRequired,
  currentTle: PropTypes.shape({
    title: PropTypes.string,
    line1: PropTypes.string,
    line2: PropTypes.string,
  }),
};

export default TleCatalogTable;
