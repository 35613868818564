import { Grid } from '@material-ui/core';
import React, { useReducer } from 'react';

import ObjectCatalogCard from './ObjectCatalogCard';
import ObjectCatalogFilters from './ObjectCatalogFilters';
import { initialState } from './ObjectCatalogTable/config';

function reducer(state, action) {
  return { ...state, ...action };
}

const ObjectCatalogPanel = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ObjectCatalogFilters dispatch={dispatch} state={state} />
      </Grid>
      <Grid item xs={12}>
        <ObjectCatalogCard filter={state} />
      </Grid>
    </Grid>
  );
};

export default ObjectCatalogPanel;
