export default (theme) => ({
  container: {
    boxShadow: 'unset',
    display: 'flex',
    flexWrap: 'wrap',
  },
  picker: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
});
