import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import StatePropShape from './DxGridPropTypes';

const SelectCheckbox = ({ selected, onToggle, state: { isAllSelected }, dispatch }) => {
  return (
    <TableCell>
      <Checkbox
        checked={isAllSelected || selected}
        onChange={(event) => {
          dispatch({ type: 'setIsAllSelected', data: false });
          onToggle(event.target.checked);
        }}
      />
    </TableCell>
  );
};

SelectCheckbox.propTypes = {
  selected: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,

  state: PropTypes.shape(StatePropShape).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default memo(SelectCheckbox);
