import { Box, Button, Card, CardContent, Grid } from '@material-ui/core';
import React from 'react';

import AddCustomTleDialog from '../AddCustomTleDialog';
import SatPicker from '../SatPicker';
import SourcePicker from '../SourcePicker';
import TleCatalogTable from '../TleCatalogTable';
import useTleCatalogPanel from './useTleCatalogPanel';

const TleCatalogPanel = () => {
  const {
    currentTle,
    dispatch,
    tleRows,
    selectedSourceOption,
    selectedSatMongoId,
    enablePullLatestOnSelected,
    pullLatestTleWhereEnabled,
    isSatSelected,
    onButtonClick,
    open,
    setOpen,
    selectedTleId,
  } = useTleCatalogPanel();
  return (
    <Card>
      <AddCustomTleDialog open={open} toggleDialog={() => setOpen((prev) => !prev)} satelliteId={selectedSatMongoId} />
      <CardContent>
        <Grid container spacing={5} style={{ marginBottom: '2em' }}>
          <Grid item xs={6} md={6} lg={6}>
            <SatPicker dispatch={dispatch} />
          </Grid>
          <Grid item xs={3} md={3} lg={3}>
            <SourcePicker selectedSourceOption={selectedSourceOption} dispatch={dispatch} disabled={!isSatSelected} />
          </Grid>
          <Grid item xs={3} md={3} lg={3}>
            <Box pt="10px">
              <Grid container spacing={1}>
                <Grid item>
                  <Button variant="outlined" color="primary" onClick={() => setOpen(true)} disabled={!isSatSelected}>
                    Add custom TLE
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => pullLatestTleWhereEnabled()}
                    disabled={!enablePullLatestOnSelected}
                  >
                    Pull Latest TLE
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <TleCatalogTable
          rows={tleRows}
          dispatch={dispatch}
          currentTle={currentTle}
          selectedSatMongoId={selectedSatMongoId}
          disablePick={selectedSourceOption !== 'fixed'}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={onButtonClick}
          disabled={selectedSourceOption !== 'fixed' || selectedTleId === ''}
        >
          Set selected TLE for Satellite
        </Button>
      </CardContent>
    </Card>
  );
};

export default TleCatalogPanel;
