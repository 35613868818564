/* eslint-disable react/prop-types */
import { Checkbox } from '@material-ui/core';
import React, { useMemo } from 'react';

export default function useTableColumns(selectedTleId, disablePick) {
  const columns = useMemo(
    () => [
      {
        Header: 'Selected',
        accessor: () => null,
        width: 100,
        Cell: ({
          cell: {
            row: {
              original: { _id },
            },
          },
        }) => {
          return <Checkbox disabled={disablePick} checked={selectedTleId === _id} />;
        },
      },
      {
        Header: 'Custom',
        accessor: 'isCustom',
        width: 100,
        Cell: ({
          cell: {
            row: {
              original: { isCustom },
            },
          },
        }) => {
          return isCustom ? 'Custom' : 'Original';
        },
      },
      {
        Header: 'Title',
        accessor: 'tle.title',
        width: 100,
      },
      {
        Header: 'Line 1',
        accessor: 'tle.line1',
      },
      {
        Header: 'Line 2',
        accessor: 'tle.line2',
      },
      {
        Header: 'Epoch',
        accessor: 'epoch',
      },
      {
        Header: 'Added on',
        accessor: 'addedOn',
      },
    ],
    [selectedTleId, disablePick],
  );

  return { columns };
}
