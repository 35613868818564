import { array, arrayOf, bool, number, shape, string } from 'prop-types';

export default {
  columns: arrayOf(shape({ name: string, title: string })).isRequired,
  pageSizes: arrayOf(number).isRequired,
  rows: array.isRequired,
  totalCount: number.isRequired,
  pageSize: number.isRequired,
  currentPage: number.isRequired,
  loading: bool.isRequired,
  lastQuery: string.isRequired,
  selection: array.isRequired,
  isAllSelected: bool.isRequired,
  sorting: arrayOf(shape({ columnName: string, direction: string })).isRequired,
};
