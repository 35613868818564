import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import DeleteFileButton from '#components/ui-helper/DeleteFileButton';
import PullButton from '#components/ui-helper/PullButton';
import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';

const ObjectCatalogCardActions = ({ classes, toggleAddNewSatelliteDialog, update }) => {
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const baseRpcEndpoint = `rg/${currentResGroup}/@c3s/orbital-element-provider`;

  return (
    <Grid container spacing={1} className={classes.CardHeader}>
      <Grid item>
        <DeleteFileButton
          title="Remove all custom satellites"
          size="medium"
          onDelete={async () => {
            await rpcWithErrorHandler(`${baseRpcEndpoint}/removeCustomSatelliteMany`, { allSelected: true });
            update();
          }}
        />
      </Grid>
      <Grid item>
        <PullButton
          title="Pull catalog"
          size="medium"
          onPull={async () => {
            await rpcWithErrorHandler(`${baseRpcEndpoint}/pullSatelliteCatalog`, { limit: 0, eventId: 'alma' });
            update();
          }}
        />
      </Grid>
      <Grid item>
        <IconButton color="primary" onClick={toggleAddNewSatelliteDialog}>
          <FontAwesomeIcon icon={faPlus} size="xs" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

ObjectCatalogCardActions.propTypes = {
  update: PropTypes.func.isRequired,
  toggleAddNewSatelliteDialog: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    CardHeader: PropTypes.string,
  }).isRequired,
};

export default ObjectCatalogCardActions;
