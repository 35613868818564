import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useReducer, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useSnackbar from '#hooks/useSnackbar';
import { initializeState } from '#utils/ReactGridColumnHelper';
import styles from '#utils/styles';

import ObjectCatalogCardActions from './ObjectCatalogCardActions';
import AddNewSatellite from './ObjectCatalogTable/AddNewSatelliteDialog';
import rawColumns from './ObjectCatalogTable/columns';
import Table from './ObjectCatalogTable/ObjectCatalogTable';

const useStyles = makeStyles(styles);

const initialState = initializeState(rawColumns);

function reducer(state, action) {
  switch (action.type) {
    case 'setRows':
      return { ...state, rows: action?.data?.length ? action.data : [] };
    case 'setTotalCount':
      return { ...state, totalCount: action.data };
    case 'setPageSize':
      return { ...state, pageSize: action.data };
    case 'setCurrentPage':
      return { ...state, currentPage: action.data };
    case 'setLoading':
      return { ...state, loading: action.data };
    case 'setSelection':
      return { ...state, selection: action.data };
    case 'setIsAllSelected':
      return { ...state, isAllSelected: action.data };
    case 'setSorting':
      return { ...state, sorting: action.data };
    case 'setHiddenColumnNames':
      return { ...state, hidden: action.data };
    default:
      throw new Error(`Invalid action type: ${action.type}`);
  }
}

const ObjectCatalogCard = ({ filter }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    rows,
    totalCount,
    selection,
    sorting,
    pageSize,
    currentPage,
    pageSizes, // const
    loading,
  } = state;
  const classes = useStyles();
  const { currentResGroup } = useContext(RgContext);
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const baseRpcEndpoint = `rg/${currentResGroup}/@c3s/orbital-element-provider`;
  const { enqueueSnackbar } = useSnackbar();
  const [newSatDialogOpen, setNewSatDialogOpen] = useState(false);

  const getSatelliteCatalog = async (requestedSkip, requestedTake) => {
    try {
      dispatch({ type: 'setLoading', data: true });
      const { result } = await rpcWithErrorHandler(`${baseRpcEndpoint}/getSatelliteCatalog`, {
        limit: requestedTake,
        skip: requestedSkip,
        filter,
        sort: { field: sorting[0].columnName, direction: sorting[0].direction },
      });
      dispatch({ type: 'setRows', data: result.result.data });
      dispatch({ type: 'setTotalCount', data: result.result.recordsTotal ?? 0 });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      dispatch({ type: 'setLoading', data: false });
    }
  };

  const update = async () => {
    await getSatelliteCatalog(currentPage * pageSize, pageSize);
  };

  function bulkDeleteSatellites(satelliteIds, allSelected) {
    try {
      rpcWithErrorHandler(`${baseRpcEndpoint}/removeCustomSatelliteMany`, {
        satelliteIds,
        allSelected,
      });
    } catch (err) {
      enqueueSnackbar(`${err.message} + asd`, { variant: 'error' });
    } finally {
      update();
    }
  }

  function bulkSyncTle(satelliteIds, allSelected, shallPullNewestTle) {
    try {
      rpcWithErrorHandler(`${baseRpcEndpoint}/setSatelliteShallPullNewestTleMany`, {
        satelliteIds,
        allSelected,
        shallPullNewestTle,
      });
      rpcWithErrorHandler(`${baseRpcEndpoint}/pullLatestTleWhereEnabled`, {});
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      update();
    }
  }

  const toggleAddNewSatelliteDialog = () => {
    setNewSatDialogOpen((prevDialogState) => !prevDialogState);
  };

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, sorting, filter]);

  return (
    <Card className={classes.Card}>
      <AddNewSatellite
        newSatDialogOpen={newSatDialogOpen}
        toggleAddNewSatelliteDialog={toggleAddNewSatelliteDialog}
        classes={classes}
        rpcWithErrorHandler={rpcWithErrorHandler}
        enqueueSnackbar={enqueueSnackbar}
        update={update}
      />
      <Grid container justify="space-between">
        <Grid item>
          <CardHeader title="Object Catalog" titleTypographyProps={{ variant: 'h6' }} className={classes.CardHeader} />
        </Grid>
        <Grid item>
          <ObjectCatalogCardActions
            classes={classes}
            toggleAddNewSatelliteDialog={toggleAddNewSatelliteDialog}
            update={update}
          />
        </Grid>
      </Grid>
      <CardContent className={classes.CardContent} style={{ marginBottom: '16px' }}>
        <Table
          bulkSyncTle={bulkSyncTle}
          bulkDeleteSatellites={bulkDeleteSatellites}
          rows={rows}
          dispatch={dispatch}
          totalCount={totalCount}
          selection={selection}
          state={state}
          update={update}
          sorting={sorting}
          pageSize={pageSize}
          pageSizes={pageSizes}
          currentPage={currentPage}
          toggleAddNewSatelliteDialog={toggleAddNewSatelliteDialog}
          loading={loading}
        />
      </CardContent>
    </Card>
  );
};

ObjectCatalogCard.propTypes = {
  filter: PropTypes.shape({}).isRequired,
};

export default ObjectCatalogCard;
