import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import React from 'react';

const ChipFormatter = ({ row }) => {
  return <Chip size="small" label={row.isCustom ? 'Custom' : 'Original'} />;
};

ChipFormatter.propTypes = {
  row: PropTypes.shape({
    isCustom: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ChipFormatter;
