// eslint-disable-next-line import/prefer-default-export
export const initialState = {
  noradId: [],
  intldes: [],
  name: [],
  type: [],
  country: [],
  site: [],
  rcs: [],
  shallPullNewestTle: [],
  launch: [Date.UTC(58), Date.now()],
  periodRange: [100, 600],
  apogeeRange: [0, 90],
  perigeeRange: [0, 90],
};
