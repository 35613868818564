import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Slider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useReducer, useState } from 'react';

import ChippedTextField from '#components/ui-helper/ChippedTextField';
import MultiSelectWithCheckbox from '#components/ui-helper/MultiSelectWithCheckbox';
import RangeInput from '#components/ui-helper/RangeInput';
import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import styles from '#utils/styles';

import { initialState } from './ObjectCatalogTable/config';

const useStyles = makeStyles(styles);

const generateDropDown = (array) =>
  array.map((value) => {
    const isBool = typeof value === 'boolean';
    // eslint-disable-next-line no-nested-ternary
    const checkedValue = isBool ? (value ? 'Enabled' : 'Disabled') : value;
    return {
      label: checkedValue,
      value,
    };
  });

function reducer(state, action) {
  return { ...state, ...action };
}

const ObjectCatalogFilters = ({ state, dispatch }) => {
  const { currentResGroup } = useContext(RgContext);
  const baseRpcEndpoint = `rg/${currentResGroup}/@c3s/orbital-element-provider`;
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);

  const classes = useStyles();
  const [localState, localDispatch] = useReducer(reducer, initialState);
  const [allFilters, setAllFilters] = useState({});

  useEffect(() => {
    async function loadFilters() {
      const filterResults = await rpcWithErrorHandler(`${baseRpcEndpoint}/getObjectCatalogFilters`, {});
      setAllFilters(filterResults);
    }
    loadFilters();
  }, [state, rpcWithErrorHandler, baseRpcEndpoint]);

  const { result } = allFilters;

  const typeArray = result?.type ? generateDropDown(result.type) : [];
  const coutryArray = result?.country ? generateDropDown(result.country) : [];
  const rcsArray = result?.rcs ? generateDropDown(result.rcs) : [];
  const syncArray = result?.shallPullNewestTle ? generateDropDown(result.shallPullNewestTle) : [];

  return (
    <Accordion id="asd" square className={classes.Accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ paddingLeft: 16 }}>
        <Typography variant="h6">Filters</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={5}>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={2}>
              <ChippedTextField
                placeholder="Cat. ID"
                passedArray={generateDropDown(localState.noradId)}
                setPassedArray={(newArray) =>
                  localDispatch({ noradId: newArray?.length ? newArray.map((v) => parseInt(v.value, 10)) : [] })
                }
              />
            </Grid>
            <Grid item xs={2}>
              <ChippedTextField
                placeholder="intDes"
                passedArray={generateDropDown(localState.intldes)}
                setPassedArray={(newArray) =>
                  localDispatch({ intldes: newArray?.length ? newArray.map((v) => v.value) : [] })
                }
              />
            </Grid>
            <Grid item xs={2}>
              <ChippedTextField
                placeholder="Name"
                passedArray={generateDropDown(localState.name)}
                setPassedArray={(newArray) =>
                  localDispatch({ name: newArray?.length ? newArray.map((v) => v.value) : [] })
                }
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <Grid item>
              <MultiSelectWithCheckbox
                title="Type"
                dropdownArray={typeArray}
                state={{ type: localState.type || [] }}
                dispatch={localDispatch}
              />
            </Grid>
            <Grid item>
              <MultiSelectWithCheckbox
                title="Country"
                dropdownArray={coutryArray}
                state={{ country: localState.country || [] }}
                dispatch={localDispatch}
              />
            </Grid>
            <Grid item>
              <MultiSelectWithCheckbox
                title="RCS"
                dropdownArray={rcsArray}
                state={{ rcs: localState.rcs || [] }}
                dispatch={localDispatch}
              />
            </Grid>
            <Grid item>
              <MultiSelectWithCheckbox
                title="Sync"
                dropdownArray={syncArray}
                state={{ shallPullNewestTle: localState.shallPullNewestTle }}
                dispatch={localDispatch}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2} xs={12}>
            <Grid item>
              <Typography>Launch</Typography>
              <RangeInput
                title="Launch Range:"
                startTime={localState.launch[0]}
                endTime={localState.launch[1]}
                onStartChange={(date) => localDispatch({ launch: [date, localState.launch[1]] })}
                onEndChange={(date) => localDispatch({ launch: [localState.launch[0], date] })}
              />
            </Grid>
            <Grid item>
              <Typography id="period-time-range">Period Time Range</Typography>
              <Slider
                defaultValue={state.periodRange}
                onChangeCommitted={(event, newValue) => {
                  localDispatch({ periodRange: newValue });
                }}
                valueLabelDisplay="auto"
                aria-labelledby="period-time-range"
                max={1000}
              />
            </Grid>
            <Grid item>
              <Typography id="apogee-range">Apogee Range</Typography>
              <Slider
                defaultValue={[0, 1000]}
                onChangeCommitted={(event, newValue) => localDispatch({ apogeeRange: newValue })}
                valueLabelDisplay="auto"
                aria-labelledby="apogee-range"
              />
            </Grid>
            <Grid item>
              <Typography id="perigee-range">Perigee Range</Typography>
              <Slider
                defaultValue={[0, 1000]}
                onChangeCommitted={(event, newValue) => localDispatch({ perigeeRange: newValue })}
                valueLabelDisplay="auto"
                aria-labelledby="perigee-range"
              />
            </Grid>
          </Grid>
          <Grid item>
            <Button color="primary" variant="contained" onClick={() => dispatch(localState)}>
              Apply Filters
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

ObjectCatalogFilters.propTypes = {
  state: PropTypes.shape({
    noradId: PropTypes.arrayOf(PropTypes.number).isRequired,
    intldes: PropTypes.arrayOf(PropTypes.string).isRequired,
    name: PropTypes.arrayOf(PropTypes.string).isRequired,
    type: PropTypes.arrayOf(PropTypes.string).isRequired,
    country: PropTypes.arrayOf(PropTypes.string).isRequired,
    rcs: PropTypes.arrayOf(PropTypes.string).isRequired,
    shallPullNewestTle: PropTypes.arrayOf(PropTypes.bool).isRequired,
    apogeeRange: PropTypes.arrayOf(PropTypes.number).isRequired,
    perigeeRange: PropTypes.arrayOf(PropTypes.number).isRequired,
    periodRange: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default ObjectCatalogFilters;
