import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React from 'react';

const RefreshRowsButton = ({ onClick }) => (
  <IconButton onClick={onClick}>
    <FontAwesomeIcon icon={['fas', 'sync-alt']} size="xs" />
  </IconButton>
);

RefreshRowsButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default RefreshRowsButton;
