import { Button, Card, CardContent, CardHeader, Dialog, Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import useAddCustomTleDialog from './useAddCustomTleDialog';

const AddCustomTleDialog = ({ open, toggleDialog, satelliteId }) => {
  const { classes, title, setTitle, line1, setLine1, line2, setLine2, addNewTle } = useAddCustomTleDialog({
    toggleDialog,
    satelliteId,
  });
  return (
    <Dialog open={open} aria-labelledby="form-dialog-title">
      <Card className={classes.Card}>
        <CardHeader title="Add custom TLE" titleTypographyProps={{ variant: 'h6' }} className={classes.CardHeader} />
        <CardContent className={classes.CardContent}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                autoFocus
                margin="dense"
                id="title"
                label="Title (Line 0)"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                autoFocus
                margin="dense"
                id="line1"
                label="Line 1"
                value={line1}
                onChange={(e) => setLine1(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                autoFocus
                margin="dense"
                id="line2"
                label="Line 2"
                value={line2}
                onChange={(e) => setLine2(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardContent className={classes.CardContent}>
          <Button variant="contained" color="secondary" onClick={toggleDialog}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={addNewTle}>
            Add
          </Button>
        </CardContent>
      </Card>
    </Dialog>
  );
};

AddCustomTleDialog.defaultProps = {
  satelliteId: '',
};

AddCustomTleDialog.propTypes = {
  satelliteId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
};

export default AddCustomTleDialog;
