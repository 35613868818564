import { makeStyles } from '@material-ui/core/styles';
import { useContext, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useSnackbar from '#hooks/useSnackbar';
import styles from '#utils/styles';

const useStyles = makeStyles(styles);

export default function useAddCustomTleDialog({ satelliteId, toggleDialog }) {
  const classes = useStyles();
  const [title, setTitle] = useState('');
  const [line1, setLine1] = useState('');
  const [line2, setLine2] = useState('');
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const baseRpcEndpoint = `rg/${currentResGroup}/@c3s/orbital-element-provider`;
  const { enqueueSnackbar } = useSnackbar();

  const addNewTle = () => {
    try {
      rpcWithErrorHandler(`${baseRpcEndpoint}/addCustomTle`, {
        satelliteId,
        tleData: {
          TLE_LINE0: title,
          TLE_LINE1: line1,
          TLE_LINE2: line2,
        },
      });
      toggleDialog();
      enqueueSnackbar('Custom TLE insertion requested', { variant: 'info' });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return { classes, title, setTitle, line1, setLine1, line2, setLine2, addNewTle };
}
