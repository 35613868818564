const getColumns = (cols) =>
  cols.map(({ field, title, hidden }) => ({
    name: field,
    title,
    hidden,
  }));

const getHeader = (cols) =>
  cols.map(({ field, title, width, render }) => ({
    field,
    render,
    title,
    cellStyle: {
      minWidth: width,
    },
  }));
const getVisibility = (cols) =>
  cols.map(({ field: columnName, togglingEnabled }) => ({
    columnName,
    togglingEnabled,
  }));
const getDefaultWidths = (cols) =>
  cols.map(({ field: columnName, width }) => ({
    columnName,
    width,
  }));
const getDefaultOrder = (cols) => cols.map(({ field: name }) => name);
const getDefaultHidden = (cols) => cols.filter(({ hidden }) => hidden).map(({ field: name }) => name);
const getSorting = (cols) =>
  cols.map(({ field: columnName, sortingEnabled = false }) => ({
    columnName,
    sortingEnabled,
  }));

// //////////////////////////////////////////

const initializeState = (columns) => ({
  columns: getColumns(columns),
  pageSizes: [10, 20, 50, 100, 500, 0],
  rows: [],
  totalCount: 0,
  pageSize: 20,
  currentPage: 0,
  loading: false,
  lastQuery: '',
  selection: [],
  isAllSelected: false,
  hidden: getDefaultHidden(columns),
  sorting: [
    {
      columnName: columns.field,
      direction: 'asc',
    },
  ],
});

function reducer(state, action) {
  switch (action.type) {
    case 'setRows':
      return {
        ...state,
        rows: action.data,
      };
    case 'setTotalCount':
      return {
        ...state,
        totalCount: action.data,
      };
    case 'setPageSize':
      return {
        ...state,
        pageSize: action.data,
      };
    case 'setCurrentPage':
      return {
        ...state,
        currentPage: action.data,
      };
    case 'setLoading':
      return {
        ...state,
        loading: action.data,
      };
    case 'setLastQuery':
      return {
        ...state,
        lastQuery: action.data,
      };
    case 'setSelection':
      return {
        ...state,
        selection: action.data,
      };
    case 'setIsAllSelected':
      return {
        ...state,
        isAllSelected: action.data,
      };
    case 'setSorting':
      return {
        ...state,
        sorting: action.data,
      };
    default:
      throw new Error(`Invalid action type: ${action.type}`);
  }
}

module.exports = {
  initializeState,
  reducer,
  getHeader,
  getColumns,
  getVisibility,
  getSorting,
  getDefaultWidths,
  getDefaultOrder,
  getDefaultHidden,
};
