import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React from 'react';

const ColumnChooserButton = ({ onToggle, buttonRef }) => (
  <IconButton onClick={onToggle} ref={buttonRef}>
    <FontAwesomeIcon icon={['fas', 'columns']} size="xs" />
  </IconButton>
);

ColumnChooserButton.propTypes = {
  onToggle: PropTypes.func.isRequired,
  buttonRef: PropTypes.func.isRequired,
};

export default ColumnChooserButton;
