import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React from 'react';

const ResetColumnWidthButton = ({ onClick }) => (
  <IconButton onClick={onClick}>
    <FontAwesomeIcon icon={['fas', 'arrows-alt-h']} size="xs" />
  </IconButton>
);

ResetColumnWidthButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ResetColumnWidthButton;
