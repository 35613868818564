import { useCallback, useContext, useEffect, useReducer, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useSnackbar from '#hooks/useSnackbar';

import initialState from './TleCatalogPanel.constants';
import reducer from './TleCatalogPanel.functions';

export default function useTleCatalogPanel() {
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const [open, setOpen] = useState(false);
  const { currentResGroup } = useContext(RgContext);
  const { enqueueSnackbar } = useSnackbar();
  const baseRpcEndpoint = `rg/${currentResGroup}/@c3s/orbital-element-provider`;

  const [
    { currentTle, tleRows, selectedSourceOption, selectedSatMongoId, selectedTleId, enablePullLatestOnSelected },
    dispatch,
  ] = useReducer(reducer, initialState);

  const isSatSelected = typeof selectedSatMongoId !== 'undefined' && selectedSatMongoId !== '';

  const getTleUsedForTracking = useCallback(async () => {
    try {
      const { result } = await rpcWithErrorHandler(`${baseRpcEndpoint}/getTleUsedForTracking`, {
        satelliteId: selectedSatMongoId,
      });
      dispatch({ currentTle: result?.result?.tle });
      dispatch({ enablePullLatestOnSelected: true });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSatMongoId]);

  const pullLatestTleWhereEnabled = () => {
    try {
      rpcWithErrorHandler(`${baseRpcEndpoint}/pullLatestTleWhereEnabled`, {});
      enqueueSnackbar('Pull latest TLE requested', { variant: 'info' });
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => pullLatestTleWhereEnabled(), []);

  useEffect(() => {
    const getSatelliteAttributes = async () => {
      try {
        const { result } = await rpcWithErrorHandler(`${baseRpcEndpoint}/getSatelliteAttributes`, {
          satelliteId: selectedSatMongoId,
        });
        if (result?.resolution === 'success') dispatch({ selectedSourceOption: result.result.tleSelectionMode });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    };

    if (isSatSelected) getSatelliteAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSatMongoId]);

  useEffect(() => {
    if (isSatSelected) getTleUsedForTracking();
  }, [selectedSatMongoId, getTleUsedForTracking, isSatSelected]);

  useEffect(() => {
    const getTlesForSatellite = async () => {
      try {
        const { result } = await rpcWithErrorHandler(`${baseRpcEndpoint}/getTlesForSatellite`, {
          satelliteId: selectedSatMongoId,
          limit: 50,
          skip: 0,
          sort: [],
        });
        dispatch({ tleRows: result.result.data });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    };
    if (isSatSelected) getTlesForSatellite();
  }, [baseRpcEndpoint, enqueueSnackbar, isSatSelected, rpcWithErrorHandler, selectedSatMongoId, open]);

  useEffect(() => {
    const setSatelliteTleSelectionMode = () => {
      try {
        rpcWithErrorHandler(`${baseRpcEndpoint}/setSatelliteTleSelectionMode`, {
          satelliteId: selectedSatMongoId,
          tleSelectionMode: selectedSourceOption,
        });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    };

    if (isSatSelected) setSatelliteTleSelectionMode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSourceOption]);

  const onButtonClick = () => {
    try {
      rpcWithErrorHandler(`${baseRpcEndpoint}/setSatelliteTleSelectionId`, {
        satelliteId: selectedSatMongoId,
        tleSelectionId: selectedTleId,
      });
      enqueueSnackbar('Current TLE modification requested', { variant: 'info' });
      getTleUsedForTracking();
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  return {
    enablePullLatestOnSelected,
    currentTle,
    dispatch,
    tleRows,
    pullLatestTleWhereEnabled,
    selectedSourceOption,
    selectedSatMongoId,
    selectedTleId,
    isSatSelected,
    onButtonClick,
    open,
    setOpen,
  };
}
