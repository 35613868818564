import { useContext, useEffect, useState } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import RgContext from '#contexts/RgContext';
import useSnackbar from '#hooks/useSnackbar';

export default function useSatPicker({ dispatch }) {
  const [selectedSat, setSelectedSat] = useState({});
  const { rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { currentResGroup } = useContext(RgContext);
  const baseRpcEndpoint = `rg/${currentResGroup}/@c3s/orbital-element-provider`;
  const { enqueueSnackbar } = useSnackbar();

  const getTrackedSatellites = async () => {
    let satOptions = [];
    try {
      const { result } = await rpcWithErrorHandler(`${baseRpcEndpoint}/getTrackedSatellites`, {});
      satOptions = result?.result?.map(({ name, _id }) => ({
        value: _id,
        label: `${name} (${_id})`,
      }));
      if (satOptions.length) dispatch({ enablePullLatestOnSelected: true });
      return satOptions;
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    return satOptions;
  };

  useEffect(() => {
    dispatch({ selectedSatMongoId: selectedSat.value, selectedTleId: '' });
  }, [selectedSat, dispatch]);

  return { setSelectedSat, getTrackedSatellites };
}
